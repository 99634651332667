<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="/blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                All Your Questions About GB WhatsApp
              </h1>

              <div class="write-by">
                {{ $global.formatTimestamp(new Date()) }} By Aamir Khan
              </div>

              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="whatsapp"
                    src="../assets/blog-cv-4.webp"></picture>
              </div>

              <h1 class="intro-title blog">
                What does a GB WhatsApp used for?
              </h1>

              <p class="writter-content">
                GB WhatsApp is also known as &quot; WhatsApp GB&quot;, &quot;Ogmods&quot; app which
                has more unique features of WhatsApp. These
                include features such as attractive themes, the ability to hide internet status,
                enabling airplane mode, and more.
              </p>

              <h1 class="intro-title blog">
                What is a GB WhatsApp Number?
              </h1>

              <p class="writter-content">
                GBWhatsApp is a modified version of the official WhatsApp that provides end-to-end
                encrypted calling and messaging capabilities,
                ensuring your communications&#39; privacy and security. Actually, It is a WhatsApp
                with rich features.
              </p>

              <h1 class="intro-title blog">
                What is the difference between GB WhatsApp and normal
                WhatsApp?
              </h1>

              <p class="writter-content">
                Please check our
                <a class="jump-url" href="https://gbwhatsapp.chat/" target="_blank">Home page.</a>
              </p>

              <h1 class="intro-title blog">
                How can I download WhatsApp 2023 GB?
              </h1>

              <p class="writter-content">
                First, allow the installation from unknown sources: Go to Settings &gt; Security
                &gt; Unknown Sources. Toggle it on. Second, Visit a
                trusted website that offers GBWhatsApp APK for download. Search for the GBWhatsApp
                APK file. Or you can
                <a class="jump-url" href="https://www.gbwhatsapp.chat/downloadpage">download
                  gbwhatsapp</a>
                from our site directly.
              </p>

              <h1 class="intro-title blog">
                Why do people prefer GB on WhatsApp?
              </h1>

              <p class="writter-content">
                GBWA provides many features and functions that are not available in the original
                WhatsApp. It enables users to explore a lot of
                features like auto-reply, privacy options, alter themes and fonts, etc.
              </p>

              <h1 class="intro-title blog">
                Is GB WhatsApp safe or unsafe?
              </h1>

              <p class="writter-content less">
                Using GBWhatsApp may result in your account being banned or blocked. Actually, most
                users use it safely.
              </p>

              <p class="writter-content">
                Security risks: GBWhatsApp is not from official WhatsApp team, because it is not
                developed or maintained by WhatsApp.
              </p>

              <h1 class="intro-title blog">
                Can WhatsApp GB hide messages?
              </h1>

              <p class="writter-content less">
                Hiding chats in GB WhatsApp is a simple process.
              </p>

              <p class="writter-content">
                To do this: Simply long-press on the chat that you want to hide. Then select the
                &quot;Hide&quot; option from the menu that appears.
              </p>

              <h1 class="intro-title blog">
                Is it legal to use WhatsApp GB?
              </h1>

              <p class="writter-content">
                According to the Whatsapp inc. , it is illegal to use such apps. But actally you can
                use it normal as official whatsapp if you obey
                the rules.
              </p>

              <h1 class="intro-title blog">
                How can I see who viewed my WhatsApp profile on GB?
              </h1>

              <p class="writter-content">
                Yes, you can know who has viewed your GBWhatsApp profile, but there has another way.
                You can decide who can see what information on
                your GBWhatsApp profile. You can empower the people you allow to see what you want
                them to see.
              </p>

              <h1 class="intro-title blog">
                Does GB WhatsApp show deleted status?
              </h1>

              <p class="writter-content">
                Once you delete a status on GBWhatsApp, it should no longer be visible to anyone,
                including the person you want to prevent from seeing
                it. However, if the person has already seen the status before you deleted it, there
                is no way to prevent them from viewing it again.
                Also people can download others&#39; status.
              </p>

              <h1 class="intro-title blog">
                Which WhatsApp is best and safe?
              </h1>

              <p class="writter-content">
                The best option is to use regular WhatsApp. If you want to enjoy the special
                features when chating with others, then the best mods is
                gb whatsapp.
              </p>

              <h1 class="intro-title blog">
                Is GB WhatsApp available for iPhone?
              </h1>

              <p class="writter-content">
                It is not possible to download and <a href="https://waproapk.com/gbwhatsapp-for-iphone" class="jump-url">install GB WhatsApp on an iPhone</a> now, as it is
                not available on the App Store and Apple does not
                allow the installation of third-party apps on its devices.
              </p>

              <h1 class="intro-title blog">
                What happens if we don&#39;t update GBWhatsApp?
              </h1>

              <p class="writter-content">
                Users who don&#39;t update GBWhatsApp may missing out on
                new features.
              </p>

              <h1 class="intro-title blog">
                What are the disadvantages of GB WhatsApp?
              </h1>

              <ul>
                <li>GB WhatsApp does not have an official license.</li>
                <li>GB WhatsApp does not guarantee end-to-end encryption.</li>
                <li>GB WhatsApp does not guarantee user security.</li>
                <li>GB WhatsApp does not guarantee data privacy.</li>
                <li>GB WhatsApp is not available on the Google Play Store.</li>
                <li>You risk getting banned when using GB WhatsApp.</li>
              </ul>

              <h1 class="intro-title blog">
                Why is WhatsApp GB banned?
              </h1>

              <p class="writter-content">
                Your account may be banned because using unofficial Whatsapp apps is against
                official Terms of Service. But if you follow the rules
                always, then it will be safe always.
              </p>

              <h1 class="intro-title blog">
                Can someone see my WhatsApp messages from another phone?
              </h1>

              <p class="writter-content">
                If they have access to your WhatsApp local or cloud backup. With your WhatsApp
                backup, they do not need your phone to read your
                WhatsApp messages.
              </p>

              <h1 class="intro-title blog">
                Does GB WhatsApp show how many times someone viewed your
                status?
              </h1>

              <p class="writter-content">
                They will only see that you have viewed their status, but GBWhatsApp does not reveal
                the number of times you did.
              </p>

              <h1 class="intro-title blog">
                Can a stranger see my WhatsApp profile?
              </h1>

              <p class="writter-content">
                You can set who can see it and who cannot see it.
              </p>

              <h1 class="intro-title blog">
                How do I get my WhatsApp GB to work?
              </h1>

              <p class="writter-content">
                Just download GB WhatsApp and use it as whatsapp.
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">FMWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'GB WhatsApp and WhatsApp spy',
          poster: require('@/assets/blog-1.jpg'),
          desc: 'Many people now use WhatsApp to send videos, photos, audio files, most importantly, photographs. However, as more people use WhatsApp, new needs emerge, such as the need for many accounts to log in at the same time',
          link: '/gbwhatsapp-apk-blog',
        },
      ],
      feature: [
        {
          feature: 'Hide Online Status',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Airplane Mode',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Status Download',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Add Custom Fonts/Stickers',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Media Sharing',
          gb: 'Maximum 200MB',
          whs: 'Maximum 15MB',
        },
        {
          feature: 'Status Character Length',
          gb: 'Maximum 255',
          whs: 'Maximum 139',
        },
        {
          feature: 'Themes Supported',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'DND Mode',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Freeze Last Seen',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Disable Forwarded Tag',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Disable/Customize Calling',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Anti-Delete Status/Messages',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Security Lock',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Fully Customize',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Auto Reply',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Increase Forward Limit',
          gb: 'Maximum 250',
          whs: 'Maximum 10',
        },
        {
          feature: 'Increase Image Share Limit',
          gb: 'Maximum 100',
          whs: 'Maximum 30',
        },
      ],
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
